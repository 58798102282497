require('./bootstrap');

$(document).ready(function () {
    
    $('.form-control.phone-mask').focus(function(){
        $(this).val('+7-').mask("+7-999-999-99-99", {placeholder: "+7-___-___-__-__"}); 
    });
    
    if ($('.gallery-block').length) {
        $('.gallery-block .owl-carousel').owlCarousel({
            loop: true,
            nav: true,
            dots: false,
            navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
            items: 2,
            autoplay: false,
            margin: 30,
            responsive: {
                0: {
                    autoHeight: true
                },
                776: {
                    autoHeight: false
                }
            }
        });
    }
    $('.site_form').ajaxForm({ 
        beforeSubmit: function(formData, form, options){
            $(form).find('input[type=submit]').attr('disabled', true);
            $(form).find('.error-item').remove();
            $(form).find('.error-field').removeClass('error-field');
            $(form).find('.site_form-error').html('');
            $(form).find('.site_form-success').html('');
        },
        success: function(response, status, xhr, form){
            $(form).find('input[type=submit]').attr('disabled', false);
            if(response.errors != undefined){
                $.each(response.errors, function(field_id, error){
                    if(field_id == 'recapthca_error'){
                        $(form).find('.recaptcha-block').append('<span class="error-item">' + error + '</span>');
                    }else{
                        $('#field_' + field_id).before('<span class="error-item">' + error + '</span>');
                        $('#field_' + field_id).addClass('error-field');
                    }
                });
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(form).find(".error-item").offset().top - 100
                }, 300);
            }else if(response.error != undefined){
                $(form).find('.site_form-error').html('<div class="alert alert-danger" role="alert">' + response.error + '</div>');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(form).offset().top - 100
                }, 300);
            }else{
                $(form).find('.site_form-error').html('<div class="alert alert-success" role="alert">' + response.msg + '</div>');
                $(form).resetForm();
                $(form).find('.form-block').addClass('d-none');
                $('.page-detail .description').addClass('d-none');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(form).offset().top - 100
                }, 300);
            }
            if($('.g-recaptcha').length){
                $.each(recaptcha, function(key, obj){
                    grecaptcha.reset(obj);
                });
            }
        }
    }); 
});
